import tracking from '../lib/tracking';
import '../../vendor/jquery.popupoverlay.js';

export default function() {
    $(function() {
        let $cookieBannerModal = $('#cookierBannerModal');
        if ($cookieBannerModal.length) {
            if ($cookieBannerModal.hasClass('takeover')) {
                $cookieBannerModal.popup({
                    autoopen: true,
                    blur: false,
                    escape: false,
                    scrolllock: true
                });
            }

            $cookieBannerModal.removeClass('hide');

            $cookieBannerModal.find('.j-accept, .j-deny').on('click', function() {
                tracking.ckEventUrl($(this).attr('data-url'));
                
                let time = 525600 * 60000; // one year in miliseconds
                let vdate = new Date();
                vdate.setTime(vdate.getTime() + time);

                let selection = $(this).hasClass('.j-accept') ? 'accept' : 'deny';
                document.cookie = 'fc_cookie_banner_accepted=' + selection + '; expires=' + vdate.toUTCString() + '; path=/';

                if ($cookieBannerModal.hasClass('takeover')) {
                    $cookieBannerModal.popup('hide');
                } else {
                    $cookieBannerModal.addClass('hide');
                }
            });
        }
    });
}